import {listInvitationStatus} from 'autogen/globalTypes';

import {graphqlClient} from '../../graphql-client';
import {
    updateListsAfterCreate,
    updateListItemsAfterCreate,
    updateListItemsAfterCreateV2,
    updateListItemsAfterDelete,
    updateListsAfterDelete,
    updateListsMarkedPrimary,
    updateListsMarkedPrimaryV2,
    updateListItemsAfterCreateMultiple,
    updateListItemsAfterDeleteAllItems,
    updateListRemoveCollaborator,
    updateListsAfterAcceptPendingInvitation,
    updateListItemsAfterDeleteItems,
    updateListsAfterCreateV2
} from '../mutation-helpers/list-mutation-helpers';
import {
    createListItemMutation,
    createListMutation,
    createMultipleListItemsMutation,
    deleteListAllItemsMutation,
    deleteListItemMutation,
    deleteMultipleListItems,
    deleteListMutation,
    removeListCollaboratorMutation,
    submitListInvitationMutation,
    updateListItemMutation,
    updateListMutation,
    updateMultipleListItemsMutation,
    confirmListInvitationMutation
} from '../list-mutations';

export const createListAction = (createListInput) =>
    graphqlClient().mutate({
        mutation: createListMutation,
        update: (cache, data) => updateListsAfterCreate(cache, data),
        variables: {
            createListInput
        }
    });

export const createListActionV2 = (createListInput) =>
    graphqlClient().mutate({
        mutation: createListMutation,
        onQueryUpdated: (observableQuery) => {
            return observableQuery.refetch();
        },
        update: (cache, data) => updateListsAfterCreateV2(cache, data),
        variables: {
            createListInput
        }
    });

export const deleteListAction = (listId) =>
    graphqlClient().mutate({
        mutation: deleteListMutation,
        update: (cache) => updateListsAfterDelete(cache, listId),
        variables: {
            listId
        }
    });

export const updateListAction = (updateListInput) =>
    graphqlClient().mutate({
        mutation: updateListMutation,
        variables: {
            updateListInput
        }
    });

export const updateMultipleListItemsAction = (listId, updateListItemInput) =>
    graphqlClient().mutate({
        mutation: updateMultipleListItemsMutation,
        variables: {
            listId,
            updateListItemInput
        }
    });

export const updatePrimaryListAction = (updateListInput) =>
    graphqlClient().mutate({
        mutation: updateListMutation,
        update: (cache, data) => updateListsMarkedPrimary(cache, data),
        variables: {
            updateListInput
        }
    });

export const updatePrimaryListActionV2 = (updateListInput) =>
    graphqlClient().mutate({
        mutation: updateListMutation,
        update: (cache, data) => updateListsMarkedPrimaryV2(cache, data),
        variables: {
            updateListInput
        }
    });

export const createListItemAction = (createListItemInput) =>
    graphqlClient().mutate({
        mutation: createListItemMutation,
        update: (cache, data) => updateListItemsAfterCreate(cache, data),
        variables: {
            createListItemInput
        }
    });

export const createListItemActionV2 = (createListItemInput) =>
    graphqlClient().mutate({
        mutation: createListItemMutation,
        update: (cache, data) => updateListItemsAfterCreateV2(cache, data),
        variables: {
            createListItemInput
        }
    });

export const createMultipleListItemsAction = (listId, createListItemsInput) =>
    graphqlClient().mutate({
        mutation: createMultipleListItemsMutation,
        update: (cache, data) => updateListItemsAfterCreateMultiple(cache, data),
        variables: {
            createListItemsInput,
            listId
        }
    });

export const deleteListItemAction = (listItemId) =>
    graphqlClient().mutate({
        mutation: deleteListItemMutation,
        optimisticResponse: {
            deleteListItem: listItemId
        },
        update: (cache) => updateListItemsAfterDelete(cache, listItemId),
        variables: {
            listItemId
        }
    });

export const updateListItemAction = (updateListItemInput) => {
    const updatedInput = {...updateListItemInput};

    delete updatedInput.product;
    delete updatedInput.addedByCustomer;
    delete updatedInput.addedBy;

    graphqlClient().mutate({
        mutation: updateListItemMutation,
        optimisticResponse: {
            updateListItem: {
                __typename: 'listItem',
                ...updatedInput
            }
        },
        variables: {
            updateListItemInput: updatedInput
        }
    });
};

export const updateListItemNoResponseAction = (updateListItemInput) => {
    const updatedInput = {...updateListItemInput};

    delete updatedInput.product;
    delete updatedInput.__typename;
    delete updatedInput.addedByCustomer;

    graphqlClient().mutate({
        mutation: updateListItemMutation,
        variables: {
            updateListItemInput: updatedInput
        }
    });
};

export const deleteListAllItemsAction = (listId) =>
    graphqlClient().mutate({
        mutation: deleteListAllItemsMutation,
        update: (cache, data) => updateListItemsAfterDeleteAllItems(cache, data),
        variables: {
            listId
        }
    });

export const deleteMultipleListItemsAction = (listId, listItemsId) =>
    graphqlClient().mutate({
        mutation: deleteMultipleListItems,
        update: (cache, data) => updateListItemsAfterDeleteItems(cache, data, listId),
        variables: {
            listId,
            listItemsId
        }
    });

export const removeListCollaboratorAction = (listId, customerUuid) =>
    graphqlClient().mutate({
        mutation: removeListCollaboratorMutation,
        update: (cache) => updateListRemoveCollaborator(cache, listId, customerUuid),
        variables: {
            removeListCollaboratorInput: {
                customerUuid,
                listId
            }
        }
    });

export const submitListInvitationAction = (listId, email) =>
    graphqlClient().mutate({
        mutation: submitListInvitationMutation,
        variables: {
            submitListInvitationInput: {
                email,
                listId
            }
        }
    });

export const confirmListInvitationAction = (token, status, list) =>
    graphqlClient().mutate({
        mutation: confirmListInvitationMutation,
        update: (cache) => {
            if (status === listInvitationStatus.ACCEPTED) {
                updateListsAfterAcceptPendingInvitation(cache, list);
            }
        },
        variables: {
            confirmListInvitationInput: {
                status,
                token
            }
        }
    });

export const leaveListAction = (listId, customerUuid) =>
    graphqlClient().mutate({
        mutation: removeListCollaboratorMutation,
        update: (cache) => updateListsAfterDelete(cache, listId),
        variables: {
            removeListCollaboratorInput: {
                customerUuid,
                listId
            }
        }
    });
