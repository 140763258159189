import {ShelfProductActionType} from 'autogen/globalTypes';

import {graphqlClient} from '../../graphql-client';
import {submitProdxShelfProductActionMutation} from '../prodx-shelf-mutations';

interface ISendProdxShelfProductActionEventParams {
    aisleId?: string;
    actionTypeId: ShelfProductActionType;
    responseId?: string | null;
    responseProductId?: string | null;
}

const sendProdxShelfProductActionEvent = ({
    actionTypeId,
    aisleId,
    responseId,
    responseProductId
}: ISendProdxShelfProductActionEventParams) => {
    if (responseId && responseProductId) {
        graphqlClient().mutate({
            mutation: submitProdxShelfProductActionMutation,
            variables: {
                submitShelfProductActionInput: {
                    actionTypeId,
                    aisleId,
                    responseId,
                    responseProductId
                }
            }
        });
    }
};

type ISubmitProdxProductViewParams = Omit<ISendProdxShelfProductActionEventParams, 'actionTypeId'>;

export const submitProdxProductView = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.PRODUCT_VIEW
    });
export const submitProdxAddToCart = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.CART_ADD
    });
export const submitProdxIncrementCart = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.CART_QUANTITY_INCREMENT
    });
export const submitProdxAddToList = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.LIST_ADD
    });
export const submitProdxRemoveToCart = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.CART_REMOVE
    });
export const submitProdxDecrementCart = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.CART_QUANTITY_DECREMENT
    });
export const submitProdxRemoveFromList = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.LIST_REMOVE
    });
export const submitProdxRightClick = (submitProdxProductViewParams: ISubmitProdxProductViewParams) =>
    sendProdxShelfProductActionEvent({
        ...submitProdxProductViewParams,
        actionTypeId: ShelfProductActionType.RIGHT_CLICK
    });
