import {gql} from '@apollo/client';

import {ListCustomerFragment} from '../queries/list-queries';

export const createListMutation = gql`
    mutation createListMutation($createListInput: createListInput!) {
        createList(createListInput: $createListInput) {
            listId
            description
            name
            isPrimary
            lastModified
            ownerCustomer {
                ...ListCustomerFragment
            }
            listCollaborators {
                ...ListCustomerFragment
            }
            created
            listItems {
                listItemId
                dealId
                listId
                created
                lastModified
                productId
                upc
                text
                quantity
                isChecked
                isWeighted
                isRemoved
                reversedOrderIndex
                type
                itemId
            }
        }
    }
    ${ListCustomerFragment}
`;
export const deleteListMutation = gql`
    mutation deleteListMutation($listId: String!) {
        deleteList(listId: $listId)
    }
`;
export const updateListMutation = gql`
    mutation updateListMutation($updateListInput: updateListInput!) {
        updateList(updateListInput: $updateListInput) {
            listId
            name
            description
            isPrimary
            lastModified
            created
            listItems {
                listItemId
                dealId
                productId
                itemId
                reversedOrderIndex
                type
            }
        }
    }
`;
export const updateMultipleListItemsMutation = gql`
    mutation updateMultipleListItems($listId: String!, $updateListItemInput: [updateListItemInput]!) {
        updateMultipleListItems(listId: $listId, updateListItemsInput: $updateListItemInput) {
            listItemId
            lastModified
            isChecked
        }
    }
`;
export const updateListItemMutation = gql`
    mutation updateListItemMutation($updateListItemInput: updateListItemInput!) {
        updateListItem(updateListItemInput: $updateListItemInput) {
            listItemId
            dealId
            created
            lastModified
            productId
            itemId
            upc
            text
            quantity
            isWeighted
            isRemoved
            isChecked
            reversedOrderIndex
        }
    }
`;
export const createListItemMutation = gql`
    mutation createListItemMutation($createListItemInput: createListItemInput!) {
        createListItem(createListItemInput: $createListItemInput) {
            listItemId
            dealId
            created
            lastModified
            productId
            itemId
            upc
            text
            quantity
            isWeighted
            isRemoved
            reversedOrderIndex
            listId
        }
    }
`;
export const createMultipleListItemsMutation = gql`
    mutation createMultipleListItemsMutation($listId: String!, $createListItemsInput: [multipleListItemInput]!) {
        createMultipleListItems(listId: $listId, createListItemsInput: $createListItemsInput) {
            listItemId
            dealId
            created
            lastModified
            productId
            itemId
            upc
            text
            quantity
            isWeighted
            isRemoved
            reversedOrderIndex
            listId
        }
    }
`;

export const deleteListItemMutation = gql`
    mutation deleteListItemMutation($listItemId: String!) {
        deleteListItem(listItemId: $listItemId)
    }
`;

export const deleteListAllItemsMutation = gql`
    mutation deleteAllListItems($listId: String!) {
        deleteAllListItems(listId: $listId)
    }
`;

export const deleteMultipleListItems = gql`
    mutation deleteMultipleListItems($listId: String!, $listItemsId: [String]!) {
        deleteMultipleListItems(listId: $listId, listItemsId: $listItemsId)
    }
`;

export const removeListCollaboratorMutation = gql`
    mutation removeListCollaborator($removeListCollaboratorInput: removeListCollaboratorInput!) {
        removeListCollaborator(removeListCollaboratorInput: $removeListCollaboratorInput)
    }
`;

export const submitListInvitationMutation = gql`
    mutation submitListInvitation($submitListInvitationInput: submitListInvitationInput!) {
        submitListInvitation(submitListInvitationInput: $submitListInvitationInput) {
            invitationId
            token
            expiration
            inviteeRegistered
            inviteeEmailed
            inviteeNotified
        }
    }
`;

export const confirmListInvitationMutation = gql`
    mutation confirmListInvitation($confirmListInvitationInput: confirmListInvitationInput!) {
        confirmListInvitation(confirmListInvitationInput: $confirmListInvitationInput) {
            status
        }
    }
`;
