import {FC, PropsWithChildren, useState} from 'react';
import {Formik} from 'formik';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import Spinner from '@hy-vee/web-core/lib/components/loading-spinner';

import {reportListEvent} from 'client/services/analytics-service';
import {createListV2, createMultipleListItems} from 'client/services/lists-service';
import {setAddToListModalError} from 'client/action-creators/lists/set-add-to-list-modal-error';
import {getListsV2} from 'autogen/getListsV2';
import ToastMessages from 'client/enums/toast-messages';

import AddToListsModalBody from './add-to-lists-modal-body';

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
`;

const SpinnerContainer = styled.div`
    display: inline-flex;
    height: 70px;
    position: relative;
    width: 70px;
`;

interface IFormProps {
    closeModal: Function;
    listData: getListsV2 | undefined;
    loading: boolean;
    listItemsToAdd: string;
}

const AddTextToListsForm: FC<PropsWithChildren<PropsWithChildren<IFormProps>>> = ({
    closeModal,
    listItemsToAdd,
    loading,
    listData
}) => {
    const addToListModalError = useSelector((state: any) => state.lists.addToListModalError);
    const [successSubmitNewList, setSuccessSubmitNewList] = useState(false);
    const [clickSaveList, setClickSaveList] = useState(false);
    const dispatch = useDispatch();
    const setAddToListModalErrorMessage = (error) => dispatch(setAddToListModalError(error));
    const [errorMessage, setErrorMessage] = useState('');
    const listItemsToAddJson = JSON.parse(listItemsToAdd);

    if (loading) {
        return (
            <LoadingContainer>
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            </LoadingContainer>
        );
    }

    return (
        <Formik
            initialValues={{
                listId: '',
                listIds: [],
                name: ''
            }}
            onSubmit={async (values, formikActions) => {
                setSuccessSubmitNewList(false);
                setErrorMessage('');

                const currentLists = listData?.lists || [];

                if (values.name && clickSaveList) {
                    const response: {data} = await createListV2(values, setAddToListModalErrorMessage);

                    if (!response?.data.createList) {
                        formikActions.setSubmitting(false);

                        return;
                    }

                    setSuccessSubmitNewList(true);
                    currentLists.push(response.data.createList);
                    reportListEvent('Create List');

                    return;
                }

                if (!values.listId) {
                    closeModal();
                }

                const listFound = currentLists.find((list) => list.listId === values.listId);

                if (listFound) {
                    try {
                        await createMultipleListItems(listFound.listItems, listFound.listId, listItemsToAddJson);
                        reportListEvent('Add Multiple Items to List');
                        closeModal();
                    } catch (error) {
                        setErrorMessage(ToastMessages.ERROR_DEFAULT);
                    }
                }

                formikActions.setSubmitting(false);
            }}
        >
            {(formikProps) => (
                <AddToListsModalBody
                    addToListModalError={addToListModalError}
                    closeModal={closeModal}
                    errorMessage={errorMessage}
                    formikProps={formikProps}
                    itemDescription={'recipe'}
                    listData={listData}
                    setClickSaveList={setClickSaveList}
                    setErrorMessage={setErrorMessage}
                    successSubmitNewList={successSubmitNewList}
                />
            )}
        </Formik>
    );
};

export default AddTextToListsForm;
