import {SortConditions} from 'client/enums/sort-conditions';

import {NextWrappedLink, WrappedLinkButton} from '../../views/components/lists/side-nav-links';

const buyAgain = {
    href: '/aisles-online/lists/frequent-purchases/buy-again',
    isBuyAgain: true,
    target: '_self',
    text: 'Buy Again',
    to: '/aisles-online/lists/frequent-purchases/buy-again'
};

export const sortLists = (lists, sortCondition = SortConditions.CREATED, customerUuid) => {
    let primaryList, otherLists;

    if (customerUuid) {
        primaryList = lists.find((list) => list.isPrimary && list.ownerCustomerUuid === customerUuid);
        otherLists = lists
            .filter((list) => list.listId !== primaryList?.listId)
            .map((list) => ({
                ...list,
                isPrimary: false
            }));
    } else {
        primaryList = lists.find((list) => list.isPrimary);
        otherLists = lists.filter((list) => !list.isPrimary);
    }

    if (sortCondition !== SortConditions.NONE) {
        otherLists.sort((listA, listB) => {
            if (sortCondition === SortConditions.CREATED) {
                return listA.created < listB.created ? -1 : 1;
            }

            return 0;
        });
    }

    return primaryList ? [primaryList, ...otherLists] : otherLists;
};

export const createListsSideNavList = (data, loading, onViewThisList, router, sortCondition, customerUuid) => {
    const sideNavLinks = [
        {
            heading: 'Shopping Lists',
            links: []
        }
    ];

    if (!loading && data?.lists) {
        sideNavLinks[0].links = sortLists(data.lists, sortCondition, customerUuid).map(
            ({listId, listItems, name, isPrimary}) => ({
                component: WrappedLinkButton,
                href: `/lists/${listId}`,
                isPrimary,
                items: listItems?.length || 0,
                listId,
                onViewThisList,
                router,
                target: '_self',
                text: name
            })
        );
    }

    sideNavLinks[0].links.push(buyAgain);

    return sideNavLinks;
};

export const createSideNavList = (data, loading, sortCondition, customerUuid) => {
    const sideNavLinks = [
        {
            heading: 'Shopping Lists',
            links: []
        }
    ];

    if (!loading && data?.lists) {
        sideNavLinks[0].links = sortLists(data.lists, sortCondition, customerUuid).map(
            ({listId, name, listItems, isPrimary}) => ({
                component: NextWrappedLink,
                href: `/lists/${listId}`,
                isPrimary,
                items: listItems?.length || 0,
                target: '_self',
                text: name
            })
        );
    }

    sideNavLinks[0].links.push(buyAgain);

    return sideNavLinks;
};

export const createBreadCrumbRoutes = (list) => {
    const breadcrumbsLinks = [];

    if (list) {
        breadcrumbsLinks.push({
            text: list.name
        });
    }

    return breadcrumbsLinks;
};

export const isItemRecentlyAdded = (created) => {
    const oneDayAgo = new Date();

    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    const addedDate = new Date(created);

    return addedDate > oneDayAgo;
};

export const extractInitialNameLastnameCustomer = (customer) => {
    return `${customer?.firstName?.[0]}${customer?.lastName?.[0]}`;
};

export const returnListString = (listItems) => {
    return listItems?.length === 1 ? 'item' : 'items';
};

const availableColors = [
    'var(--color-blue--30)',
    'var(--color-mustard-yellow)',
    'var(--color-forest-green)',
    'var(--color-magenta)',
    'var(--color-gray--60)'
];

const getUserHash = (str) => {
    return [...str].reduce((hash, char) => {
        return Math.imul(hash, 32) - hash + char.charCodeAt(0);
    }, 0);
};

export const getListColorUser = (customerUuid) => {
    const hash = getUserHash(customerUuid);
    const index = Math.abs(hash) % availableColors.length;

    return availableColors[index];
};

export const getListColorUsers = (collaborators) => {
    return collaborators.map((collaborator) => {
        return {
            ...collaborator,
            color: getListColorUser(collaborator.customerUuid)
        };
    });
};
