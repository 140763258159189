import {ApolloCache} from '@apollo/client';

import {getListAndListItemsWithDepartmentByListId, getLists, getListsV2} from 'client/graphql/queries/list-queries';
import {getLists_lists, getLists_lists_listItems} from 'autogen/getLists';

import {listForCacheIdentify, listItemForCacheIdentify} from './cache-identify-helpers';

export const updateListsAfterCreate = (client, {data}) => {
    const {lists} = client.readQuery({
        query: getLists
    });

    client.writeQuery({
        data: {
            lists: [...lists, data.createList]
        },
        query: getLists
    });
};

export const updateListsAfterCreateV2 = (client, {data}) => {
    const {lists} = client.readQuery({
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });

    client.writeQuery({
        data: {
            lists: [...lists, data.createList]
        },
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });
};

export const updateListsAfterDelete = (cache: ApolloCache<getLists_lists>, listId) => {
    const listToDelete = cache.identify(listForCacheIdentify(listId));

    cache.evict({id: listToDelete});
    cache.gc();
};

export const updateListItemsAfterCreate = (client, {data}) => {
    const {lists} = client.readQuery({
        query: getLists
    });

    const currentList = lists.find((list) => data.createListItem.listId === list.listId);
    const otherLists = lists.filter((list) => data.createListItem.listId !== list.listId);

    const updatedList = {
        ...currentList,
        listItems: [data.createListItem, ...currentList.listItems]
    };

    client.writeQuery({
        data: {
            lists: [...otherLists, updatedList]
        },
        query: getLists
    });
};

export const updateListItemsAfterDeleteItems = (client, {data}, listId) => {
    const {lists} = client.readQuery({
        query: getLists,
        variables: {
            includeCollaborations: true
        }
    });
    const currentList = lists.find((list) => listId === list.listId);
    const otherLists = lists.filter((list) => listId !== list.listId) || [];
    const deleteditems = data?.deleteMultipleListItems || [];
    const updatedList = {
        ...currentList,
        listItems: currentList.listItems.filter((listItem) => !deleteditems.includes(listItem.listItemId))
    };

    client.writeQuery({
        data: {
            lists: [...otherLists, updatedList]
        },
        query: getLists,
        variables: {
            includeCollaborations: true
        }
    });
};

export const updateListItemsAfterCreateV2 = (client, {data}) => {
    const {lists} = client.readQuery({
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });

    const currentList = lists.find((list) => data.createListItem.listId === list.listId);
    const otherLists = lists.filter((list) => data.createListItem.listId !== list.listId);

    const updatedList = {
        ...currentList,
        listItems: [data.createListItem, ...currentList.listItems]
    };

    client.writeQuery({
        data: {
            lists: [...otherLists, updatedList]
        },
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });
};

export const updateListItemsAfterDeleteAllItems = (client, data) => {
    const {lists} = client.readQuery({
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });

    if (!Array.isArray(lists)) {
        return;
    }

    const currentList = lists.find((list) => {
        if (typeof list !== 'object' || list === null) {
            return false;
        }

        return data.deleteAllListItems === list.listId;
    });

    if (!currentList) {
        return;
    }

    const otherLists = lists.filter((list) => {
        if (typeof list !== 'object' || list === null) {
            return false;
        }

        return data.deleteAllListItems !== list.listId;
    });

    const updatedList = {
        ...currentList,
        listItems: []
    };

    client.writeQuery({
        data: {
            lists: [...otherLists, updatedList]
        },

        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });
};

export const updateListItemsAfterCreateMultiple = (client, {data}) => {
    const {lists} = client.readQuery({
        query: getLists
    });

    const currentList = lists.find((list) => data.createMultipleListItems[0].listId === list.listId);
    const otherLists = lists.filter((list) => data.createMultipleListItems[0].listId !== list.listId);

    currentList.listItems.unshift([...data.createMultipleListItems]);

    client.writeQuery({
        data: {
            lists: [...otherLists, currentList]
        },
        query: getLists
    });
};

export const updateListItemsAfterDelete = (cache: ApolloCache<getLists_lists_listItems>, listItemId) => {
    const listItemToDelete = cache.identify(listItemForCacheIdentify(listItemId));

    cache.evict({id: listItemToDelete});
    cache.gc();
};

export const updateListsMarkedPrimary = (client, {data}) => {
    const {lists} = client.readQuery({
        query: getLists
    });
    const newLists = lists
        .map((list) => ({
            ...list,
            isPrimary: false
        }))
        .filter((list) => data.updateList.listId !== list.listId);
    const fixedLists = [...newLists, data.updateList];

    client.writeQuery({
        data: {
            lists: fixedLists
        },
        query: getLists
    });
};

export const updateListsMarkedPrimaryV2 = (client, {data}) => {
    const {lists} = client.readQuery({
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });
    const newLists = lists
        .map((list) => ({
            ...list,
            isPrimary: false
        }))
        .filter((list) => data.updateList.listId !== list.listId);
    const fixedLists = [...newLists, data.updateList];

    client.writeQuery({
        data: {
            lists: fixedLists
        },
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });
};

export const updateListRemoveCollaborator = (client, {listId, customerUuid}) => {
    const {lists} = client.readQuery({
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });

    if (!Array.isArray(lists)) {
        return;
    }

    const currentList = lists.find((list) => {
        if (typeof list !== 'object' || list === null) {
            return false;
        }

        return listId === list.listId;
    });

    if (!currentList || !Array.isArray(currentList.listCollaborators)) {
        return;
    }

    const newListCollaborators = currentList.listCollaborators.filter((user) => {
        if (typeof user !== 'object' || user === null) {
            return false;
        }

        return user.customerUuid !== customerUuid;
    });

    const otherLists = lists.filter((list) => {
        if (typeof list !== 'object' || list === null) {
            return false;
        }

        return listId !== list.listId;
    });

    const newList = {
        ...currentList,
        listCollaborators: newListCollaborators
    };

    client.writeQuery({
        data: {
            list: newList
        },
        query: getListAndListItemsWithDepartmentByListId,
        variables: {
            listId,
            storeId: 1509
        }
    });

    client.writeQuery({
        data: {
            lists: [...otherLists, newList]
        },
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });
};

export const updateListsAfterAcceptPendingInvitation = (client, list) => {
    const {lists} = client.readQuery({
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });

    client.writeQuery({
        data: {
            lists: [...lists, list]
        },
        query: getListsV2,
        variables: {
            includeCollaborations: true
        }
    });
};
