import {useState, FC, PropsWithChildren} from 'react';
import {OutlineButton} from '@hy-vee/web-core/lib/components/button';
import Checkbox from '@hy-vee/web-core/lib/components/checkbox';
import {Span} from '@hy-vee/web-core/lib/components/typography';
import Input from '@hy-vee/web-core/lib/components/input';
import {hyveeRedOutline} from '@hy-vee/themes';
import {AddCircleIcon} from '@hy-vee/icons';
import isEqual from 'lodash.isequal';
import type {FormikProps} from 'formik';

import {getLists, getLists_lists} from 'autogen/getLists';
import {sortLists} from 'client/utils/view-helpers/list-helpers';

import {
    StyledModalBody,
    StyledToggleButtonText,
    StyledLinkButton,
    StyledButton,
    StyledButtonContainer,
    StyledRowContainer,
    StyledCreateListForm
} from './styles';

interface IFormikPropValues {
    name: string;
    listIds: string[];
}

interface IModalBodyProps {
    closeModal: Function;
    errorMessage: string;
    formikProps: FormikProps<IFormikPropValues>;
    itemDescription?: string;
    listData?: getLists;
    setErrorMessage: Function;
}

const renderCheckbox = (listId, name, formikProps) => (
    <Checkbox
        checked={formikProps.values.listIds?.includes(listId) || false}
        id={listId}
        key={listId}
        label={<Span>{name}</Span>}
        name="listIds"
        onChange={formikProps.handleChange}
        value={listId}
    />
);

const getModalText = (lists: getLists_lists[], itemDescription) => {
    if (!lists.length) {
        return "You don't have any lists. Would you like to create one?";
    }

    return itemDescription
        ? `Which list/s do you want to add this ${itemDescription} to?`
        : 'Which list/s do you want to add this item to?';
};

const AddToListsModalBody: FC<PropsWithChildren<PropsWithChildren<IModalBodyProps>>> = ({
    closeModal,
    listData,
    errorMessage,
    formikProps,
    itemDescription,
    setErrorMessage
}) => {
    const lists = listData?.lists || [];
    const [showInput, setShowInput] = useState(!lists.length);
    const valuesHaveNotChanged = isEqual(formikProps.initialValues, formikProps.values);

    return (
        <StyledCreateListForm onSubmit={formikProps.handleSubmit}>
            <StyledModalBody>
                <div>{getModalText(lists, itemDescription)}</div>
                {sortLists(lists).map(({listId, name}) => renderCheckbox(listId, name, formikProps))}
                {lists.length ? (
                    <StyledLinkButton disabled={showInput} onClick={() => setShowInput(true)}>
                        <AddCircleIcon />
                        <StyledToggleButtonText>{'Add a New List'}</StyledToggleButtonText>
                    </StyledLinkButton>
                ) : null}
                {showInput && (
                    <StyledRowContainer>
                        <Input
                            autoFocus
                            error={formikProps.errors.name || errorMessage}
                            id="name"
                            label="New List Name"
                            margin={false}
                            onChange={(event) => {
                                formikProps.handleChange(event);

                                if (errorMessage) {
                                    setErrorMessage('');
                                }
                            }}
                            value={formikProps.values.name}
                        />
                    </StyledRowContainer>
                )}
                <StyledButtonContainer>
                    <OutlineButton onClick={closeModal} theme={hyveeRedOutline}>
                        {'Cancel'}
                    </OutlineButton>
                    <StyledButton
                        data-testid={'save-to-list-button'}
                        disabled={valuesHaveNotChanged}
                        isLoading={formikProps.isSubmitting}
                        type={'submit'}
                    >
                        {showInput ? 'Create & Save to List(s)' : 'Save to List(s)'}
                    </StyledButton>
                </StyledButtonContainer>
            </StyledModalBody>
        </StyledCreateListForm>
    );
};

export default AddToListsModalBody;
