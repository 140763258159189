enum ToastMessages {
    SUCCESS_DEFAULT = 'Action successful',
    ERROR_DEFAULT = 'Error occurred. Please try again later.',
    WARNING_DEFAULT = 'Item already in list',
    STANDARD_DEFAULT = '',
    SUCCESS_INVITE = 'Invite sent',
    SUCCESS_REMOVE_INVITE = 'Collaborator removed',
    SUCCESS_LIST_UPDATE = 'List name updated',
    SUCCESS_LIST_CREATED = 'List created',
    SUCCESS_ALL_DELETED = 'All items deleted',
    SUCCESS_LIST_DELETED = 'List deleted',
    SUCCESS_ITEM_CREATED = 'Item added to list',
    SUCCESS_ITEM_UNCHECKED = 'Item unchecked',
    SUCCESS_ITEM_COMPLETED = 'Item completed'
}

export default ToastMessages;
