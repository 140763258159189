import {useQuery} from '@apollo/client';

import IApolloQuery from 'client/types/apollo-query';
import {useCustomerData} from 'client/hooks/customer-hooks';
import {useLocationId} from 'client/hooks/location-hooks';
import {getItemByItemId as getItemByItemIdQuery} from 'client/graphql/queries/product-queries';
import {GetItemByItemId, GetItemByItemIdVariables} from 'autogen/GetItemByItemId';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {EWIC_STORES} from 'client/enums/feature-toggle-names';

const useItem = (itemId: string | null) => {
    const {storeId} = useCustomerData();
    const {locationIds, locationIdLoading} = useLocationId(storeId?.toString(), false);
    const {featuresEnabled} = useFeatureToggle();

    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const {loading: productLoading, data: itemData} = useQuery<GetItemByItemId, IApolloQuery<GetItemByItemIdVariables>>(
        getItemByItemIdQuery,
        {
            errorPolicy: 'all',
            skip: !storeId || !itemId || locationIdLoading,
            variables: {
                itemId: itemId ?? '',
                locationIds,
                retailItemEnabled: Boolean(locationIds.length),
                wicEnabled
            }
        }
    );

    return {
        isLoading: productLoading,
        item: itemData?.itemById
    };
};

export default useItem;
