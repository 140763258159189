import {FC, PropsWithChildren} from 'react';
import {IDType} from '@hy-vee/ts-utils';
import {useQuery} from '@apollo/client';

import {getLists} from 'autogen/getLists';
import useItem from 'client/hooks/use-item';
import {getDealByDealId} from 'autogen/getDealByDealId';
import {graphqlClient} from 'client/graphql/graphql-client';
import {TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import * as listQueries from 'client/graphql/queries/list-queries';
import * as dealQueries from 'client/graphql/queries/deal-queries';
import * as productQueries from 'client/graphql/queries/product-queries';
import {getProductNameByProductId} from 'autogen/getProductNameByProductId';

import AddProductOrDealToListForm from './add-product-or-deal-to-list-form';
import AddTextToListsForm from './add-text-to-lists-form';

interface IModalProps {
    aisleId?: string;
    closeModal: Function;
    dealId?: string[] | string;
    itemId?: string;
    listItemsToAdd?: string;
    productId?: IDType;
    responseProductId?: string | null;
    responseId?: string | null;
}

const AddToListsModalContainer: FC<PropsWithChildren<PropsWithChildren<IModalProps>>> = (props) => {
    const {closeModal, dealId, itemId, listItemsToAdd, productId} = props;
    const {data: listData, loading: listsLoading} = useQuery<getLists>(listQueries.getLists, {
        client: graphqlClient()
    });
    const {data: productData, loading: productLoading} = useQuery<getProductNameByProductId>(
        productQueries.getProductNameByProductId,
        {
            client: graphqlClient(),
            skip: !productId,
            variables: {
                authType: TWO_LEGGED_AUTH_TYPE,
                productId: Number(productId)
            }
        }
    );
    const {data: dealData, loading: dealsLoading} = useQuery<getDealByDealId>(dealQueries.getDealByDealId, {
        client: graphqlClient(),
        skip: !dealId,
        variables: {
            dealId: Number(dealId)
        }
    });
    const {isLoading: itemLoading, item: itemData} = useItem(itemId ?? null);
    const queriesLoading = listsLoading || productLoading || dealsLoading || itemLoading;

    if (productId || dealId || itemId) {
        return (
            <AddProductOrDealToListForm
                data-testid="add-to-lists-form"
                dealData={dealData}
                itemData={itemData}
                listData={listData}
                loading={queriesLoading}
                productData={productData}
                {...props}
            />
        );
    }

    if (!listItemsToAdd) {
        closeModal();
    }

    return (
        <AddTextToListsForm
            data-testid="add-to-lists-form"
            listData={listData}
            listItemsToAdd={listItemsToAdd || ''}
            loading={listsLoading}
            {...props}
        />
    );
};

export default AddToListsModalContainer;
