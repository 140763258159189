import styled from 'styled-components';
import {spacing} from '@hy-vee/themes';
import {LinkButton, Button} from '@hy-vee/web-core/lib/components/button';

export const StyledModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const StyledToggleButtonText = styled.span`
    margin-left: ${spacing.small};
`;

export const StyledLinkButton = styled(LinkButton)`
    margin-top: ${spacing.medium};
`;

export const StyledRowContainer = styled.div`
    margin-top: ${spacing.medium};
    width: 100%;
    max-width: 340px;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    margin-top: ${spacing.medium};
    margin-bottom: ${spacing.large};
    width: 100%;
    max-width: 360px;

    flex-direction: column;

    @media (min-width: 350px) {
        flex-direction: row;
    }
`;

export const StyledButton = styled(Button)`
    @media (min-width: 350px) {
        margin-left: ${spacing.medium};
    }
`;

export const StyledCreateListForm = styled.form``;
