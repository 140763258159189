import {useState, FC, PropsWithChildren, useMemo} from 'react';
import {RatingIcon, AddIcon, CheckCircleIcon, WarningIcon} from '@hy-vee/icons';
import isEqual from 'lodash.isequal';
import type {FormikProps} from 'formik';
import {Button, Text, TextInput} from '@hy-vee/design-system';

import {sortLists} from 'client/utils/view-helpers/list-helpers';
import {SortConditions} from 'client/enums/sort-conditions';
import {getListsV2} from 'autogen/getListsV2';
import {useCustomerUuid} from 'client/hooks/customer-hooks';

import modalStyles from '../create-edit-list-modal-body.module.css';
import formStyles from '../forms.module.css';
import {AnalyticsDetails, AnalyticsTypes} from '../../../../enums/lists-v2';

import addToListModalStyles from './styles.module.css';

interface IFormikPropValues {
    name: string;
    listIds: string[];
}

interface IModalBodyProps {
    closeModal: Function;
    errorMessage: string;
    addToListModalError: string;
    formikProps: FormikProps<IFormikPropValues>;
    itemDescription?: string;
    listData?: getListsV2;
    successSubmitNewList: boolean;
    setClickSaveList: Function;
    setErrorMessage: Function;
}

const renderRadioButton = (listId, name, formikProps, isPrimary, listItems) => (
    <div className={addToListModalStyles.radioButtonContainer} data-testid="list-item">
        <input
            className={addToListModalStyles.radio}
            id={listId}
            key={listId}
            name="listId"
            onChange={formikProps.handleChange}
            type="radio"
            value={listId}
        />
        <label className={addToListModalStyles.list} htmlFor={listId}>
            <span className={addToListModalStyles.listTitle}>
                {isPrimary && <RatingIcon color={'#CC3000'} size="small" />}
                {name}
            </span>
            <span className={addToListModalStyles.totalItemsList}>
                {listItems.length} {'items'}
            </span>
        </label>
    </div>
);

const AddToListsModalBody: FC<PropsWithChildren<PropsWithChildren<IModalBodyProps>>> = ({
    listData,
    errorMessage,
    addToListModalError,
    formikProps,
    successSubmitNewList,
    setClickSaveList,
    setErrorMessage
}) => {
    const lists = listData?.lists || [];
    const [showInput, setShowInput] = useState(!lists.length);
    const valuesHaveNotChanged = isEqual(formikProps.initialValues, formikProps.values);
    const customerUuid = useCustomerUuid();

    const memoizedRadioButtons = useMemo(() => {
        return sortLists(listData?.lists, SortConditions.NONE, customerUuid).map(
            ({listId, name, isPrimary, listItems}) => renderRadioButton(listId, name, formikProps, isPrimary, listItems)
        );
    }, [listData?.lists, formikProps, customerUuid]);

    return (
        <div className={modalStyles.modalContainer}>
            <h2 className={addToListModalStyles.titleModal}>{'Add to list'}</h2>
            <form className={addToListModalStyles.form} onSubmit={formikProps.handleSubmit}>
                {!lists.length ? (
                    <Text textStyle={'body1'}>{'Please add a list to add products'}</Text>
                ) : (
                    memoizedRadioButtons
                )}

                <button
                    className={addToListModalStyles.addNewListButton}
                    data-testid="add-new-list-cta"
                    disabled={showInput}
                    onClick={() => {
                        setErrorMessage('');
                        setShowInput(true);
                    }}
                    type="button"
                >
                    <AddIcon color={showInput && '#cccccc'} />
                    <div className={addToListModalStyles.addNewListButtonText}>{'Add to new list'}</div>
                </button>

                {showInput && (
                    <div className={modalStyles.createEditFormContainer}>
                        <div
                            className={`${modalStyles.formContainerField} ${addToListModalStyles.createEditFormContainerField}`}
                        >
                            <TextInput
                                autoFocus
                                className={`
                                ${formStyles.input}
                                ${addToListModalStyles.input}
                                ${successSubmitNewList && formStyles.inputSuccess}
                                ${formikProps.errors.name && formStyles.inputError}
                            `}
                                classNameErrorBox={`
                                ${formStyles.inputErrorText}
                                ${formikProps.errors.name && modalStyles.errorMessage}
                            `}
                                data-testid="add-new-list-field"
                                error={formikProps.errors.name || addToListModalError}
                                id="name"
                                label="Add new list"
                                name="name"
                                onChange={(event) => {
                                    formikProps.handleChange(event);
                                }}
                                placeholder="Name your list (required)"
                                value={formikProps.values.name}
                            />
                            <Button
                                data-analytics-type={AnalyticsTypes.CreateList}
                                disabled={valuesHaveNotChanged}
                                onClick={() => setClickSaveList(true)}
                                type={'submit'}
                            >
                                {'Save'}
                            </Button>
                        </div>
                        {successSubmitNewList && (
                            <div
                                className={modalStyles.createEditFormContainerSuccess}
                                data-testid="list-success-submit"
                            >
                                <CheckCircleIcon color="var(--color-green--30)" size="small" />
                                {'List successfully created'}
                            </div>
                        )}
                    </div>
                )}
                {errorMessage && (
                    <div className={addToListModalStyles.errorBox} data-testid="error-message" role="alert">
                        <WarningIcon color="var(--color-red--30)" informative size="small" />
                        <Text className={addToListModalStyles.errorBoxMessage} textStyle="body3">
                            {errorMessage}
                        </Text>
                    </div>
                )}
                <div className={addToListModalStyles.doneButton}>
                    <Button
                        block
                        data-analytics-detail={AnalyticsDetails.Tile}
                        data-analytics-type={AnalyticsTypes.AddToList}
                        isLoading={formikProps.isSubmitting}
                        onClick={() => {
                            setClickSaveList(false);
                            formikProps.handleSubmit();
                        }}
                    >
                        {'Done'}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddToListsModalBody;
