import {gql} from '@apollo/client';

import {productImageFragment} from './product-images-queries';

export const ListNameFragment = gql`
    fragment ListNameFragment on list {
        listId
        name
    }
`;

export const ListCustomerFragment = gql`
    fragment ListCustomerFragment on customer {
        firstName
        lastName
        username
        customerId
        customerUuid
    }
`;

export const getListNamesForSideNav = gql`
    query getListNamesForSideNav {
        lists {
            ...ListNameFragment
            isPrimary
            created
        }
    }
    ${ListNameFragment}
`;

export const getLists = gql`
    query getLists {
        lists {
            ...ListNameFragment
            description
            isPrimary
            lastModified
            created
            listItems {
                listItemId
                dealId
                itemId
                productId
                reversedOrderIndex
                type
            }
        }
    }
    ${ListNameFragment}
`;

export const getListsV2 = gql`
    query getListsV2($includeCollaborations: Boolean = false) {
        lists(includeCollaborations: $includeCollaborations) {
            ...ListNameFragment
            isPrimary
            lastModified
            created
            ownerCustomerUuid
            ownerCustomer {
                ...ListCustomerFragment
            }
            listCollaborators {
                ...ListCustomerFragment
            }
            listItems {
                addedBy
                listItemId
                listId
                dealId
                created
                productId
                text
                reversedOrderIndex
                itemId
                isChecked
                quantity
                type
            }
        }
    }
    ${ListNameFragment}
    ${ListCustomerFragment}
`;

export const getListByListId = gql`
    query getListByListId($listId: String!) {
        list(listId: $listId) {
            ...ListNameFragment
            listItems {
                listItemId
                listId
                itemId
                text
                quantity
                isRemoved
                reversedOrderIndex
                type
            }
        }
    }
    ${ListNameFragment}
`;

export const getListsAndListItems = gql`
    query getListsAndListItems {
        lists {
            ...ListNameFragment
            description
            isPrimary
            lastModified
            created
            listItems {
                listItemId
                dealId
                listId
                created
                lastModified
                productId
                upc
                text
                quantity
                isWeighted
                isRemoved
                isChecked
                reversedOrderIndex
                type
                itemId
            }
        }
    }
    ${ListNameFragment}
`;

export const getListsAndListItemsWithDepartment = gql`
    query getListsAndListItemsWithDepartments($storeId: Int!, $includeCollaborations: Boolean!) {
        lists(includeCollaborations: $includeCollaborations) {
            ...ListNameFragment
            description
            isPrimary
            lastModified
            created
            ownerCustomer {
                ...ListCustomerFragment
            }
            listCollaborators {
                ...ListCustomerFragment
            }
            listItems {
                addedByCustomer {
                    ...ListCustomerFragment
                }
                listItemId
                dealId
                listId
                created
                lastModified
                productId
                upc
                text
                quantity
                isWeighted
                isRemoved
                isChecked
                reversedOrderIndex
                type
                itemId
                isChecked
                product {
                    productId
                    storeProduct(storeId: $storeId) {
                        storeProductId
                        departmentGroup {
                            departmentGroupId
                            name
                        }
                    }
                }
            }
        }
    }
    ${ListNameFragment}
    ${ListCustomerFragment}
`;

export const getListAndListItemsWithDepartmentByListId = gql`
    query getListAndListItemsWithDepartmentByListId($listId: String!, $storeId: Int!) {
        list(listId: $listId) {
            ...ListNameFragment
            created
            lastModified
            isPrimary
            isRemoved
            ownerCustomerUuid
            ownerCustomer {
                ...ListCustomerFragment
            }
            listItems {
                addedBy
                addedByCustomer {
                    ...ListCustomerFragment
                }
                listItemId
                dealId
                listId
                created
                lastModified
                productId
                upc
                text
                quantity
                isWeighted
                isRemoved
                isChecked
                reversedOrderIndex
                type
                itemId
                isChecked
                product {
                    productId
                    productImages(where: {isPrimary: true, size: "THUMBNAIL"}) {
                        ...ProductImageFragment
                    }
                    storeProduct(storeId: $storeId) {
                        storeProductId
                        departmentGroup {
                            departmentGroupId
                            name
                        }
                    }
                }
            }
            listCollaborators {
                ...ListCustomerFragment
            }
        }
    }
    ${ListNameFragment}
    ${ListCustomerFragment}
    ${productImageFragment}
`;

export const getListItemProductDetailsByProductId = gql`
    ${productImageFragment}

    query getListItemProductDetailsByProductId($productId: Int!, $size: String!) {
        product(productId: $productId) {
            productId
            name
            productImages(where: {isPrimary: true, size: $size}) {
                ...ProductImageFragment
            }
        }
    }
`;

export const getListItem = gql`
    query getListItem($listItemId: String!) {
        listItem(listItemId: $listItemId) {
            listItemId
            created
            lastModified
            productId
            upc
            text
            quantity
            isWeighted
            isChecked
            isRemoved
            dealId
            type
            itemId
            reversedOrderIndex
        }
    }
`;

export const getPendingInvitationsAndQuickAddCustomersList = gql`
    query getPendingInvitationsAndQuickAddCustomersList($where: listInvitationsInput, $listId: String!) {
        listInvitations(where: $where) {
            invitationId
            list {
                listId
            }
            customer {
                ...ListCustomerFragment
            }
            invitationEmail
            token
            created
            expiration
            status
        }
        suggestedListCollaborators(listId: $listId) {
            ...ListCustomerFragment
        }
    }
    ${ListCustomerFragment}
`;

export const getReceivedPendingInvitations = gql`
    query getReceivedPendingInvitations($where: listInvitationsInput) {
        listInvitations(where: $where) {
            invitationId
            list {
                listId
                name
                description
                isPrimary
                lastModified
                created
                ownerCustomer {
                    ...ListCustomerFragment
                }
                listCollaborators {
                    ...ListCustomerFragment
                }
                listItems {
                    isChecked
                    listItemId
                    dealId
                    listId
                    created
                    lastModified
                    productId
                    upc
                    text
                    quantity
                    isWeighted
                    isRemoved
                    reversedOrderIndex
                    type
                    itemId
                }
            }
            customer {
                ...ListCustomerFragment
            }
            invitationEmail
            token
            created
            expiration
            status
        }
    }
    ${ListCustomerFragment}
`;
