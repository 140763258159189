export enum AnalyticsDetails {
    DeclineInvitation = 'decline_invitation',
    AcceptInvitation = 'accept_invitation',
    AddCollaborator = 'add_collaborator',
    RemoveCollaborator = 'remove_collaborator',
    QuickAddCollaborator = 'quick_add_collaborator',
    QuickAdd = 'quick add',
    ItemCompleted = 'item_completed',
    ResendCollaboratorInvite = 'resend_collaborator_invite',
    Tile = 'tile'
}

export enum AnalyticsTypes {
    AddToList = 'add_to_list',
    AddListCollaborator = 'add_list_collaborator',
    UpdateList = 'update_list',
    DeleteList = 'delete_list',
    SharedList = 'shared_list',
    SelectList = 'select_list',
    ListInteraction = 'list_interaction',
    EditListSettings = 'edit_list_settings',
    CreateList = 'create_list'
}

export enum AnalyticsValues {
    DeleteAllItems = 'delete_all_items',
    Shared = 'shared',
    Personal = 'personal',
    LeaveList = 'leave list',
    CheckAllItemsComplete = 'check_all_items_complete',
    DeleteCompletedItems = 'delete_completed_items',
    MakePrimary = 'make primary',
    QuickStart = 'quick_start'
}
