import {FC, PropsWithChildren, useState} from 'react';
import {Formik} from 'formik';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import Spinner from '@hy-vee/web-core/lib/components/loading-spinner';

import {getDealByDealId} from 'autogen/getDealByDealId';
import {reportListEvent} from 'client/services/analytics-service';
import {getProductNameByProductId} from 'autogen/getProductNameByProductId';
import {setAddToListModalError} from 'client/action-creators/lists/set-add-to-list-modal-error';
import {createListV2, createListItemV2} from 'client/services/lists-service';
import {submitProdxAddToList} from 'client/graphql/mutations/mutation-actions/prodx-shelf-mutations-actions';
import {GetItemByItemId_itemById} from 'autogen/GetItemByItemId';
import {getListsV2, getListsV2_lists} from 'autogen/getListsV2';
import ToastMessages from 'client/enums/toast-messages';

import AddToListsModalBody from './add-to-lists-modal-body';

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
`;

const SpinnerContainer = styled.div`
    display: inline-flex;
    height: 70px;
    position: relative;
    width: 70px;
`;

const determineInitiallySelectedListIds = (
    lists: getListsV2_lists[],
    productId?: string,
    dealId?: string,
    itemId?: string
) =>
    lists.reduce((selectedListIds, list) => {
        const hasItemInList = list.listItems?.find((listItem) => {
            if (productId) {
                return listItem.productId === Number(productId);
            } else if (dealId) {
                return listItem.dealId === Number(dealId);
            }

            return listItem.itemId === itemId;
        });

        if (hasItemInList) {
            return [...selectedListIds, list.listId];
        }

        return selectedListIds;
    }, []);

interface IFormProps {
    aisleId?: string;
    closeModal: Function;
    dealData?: getDealByDealId;
    itemData?: GetItemByItemId_itemById | null;
    listData?: getListsV2;
    loading: boolean;
    productData?: getProductNameByProductId;
    responseId?: string | null;
    responseProductId?: string | null;
}

const AddProductOrDealToListForm: FC<PropsWithChildren<PropsWithChildren<IFormProps>>> = ({
    aisleId,
    closeModal,
    dealData,
    itemData,
    loading,
    listData,
    productData,
    responseId,
    responseProductId
}) => {
    const addToListModalError = useSelector((state: any) => state.lists.addToListModalError);
    const [successSubmitNewList, setSuccessSubmitNewList] = useState(false);
    const [clickSaveList, setClickSaveList] = useState(false);
    const dispatch = useDispatch();
    const setAddToListModalErrorMessage = (error) => dispatch(setAddToListModalError(error));
    const [errorMessage, setErrorMessage] = useState('');

    if (loading) {
        return (
            <LoadingContainer>
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            </LoadingContainer>
        );
    }

    const currentLists = listData?.lists || [];

    if (!productData && !dealData && !itemData) {
        return null;
    }

    const productId = productData?.product?.productId;
    const dealId = dealData?.deal?.dealId;
    const itemId = itemData?.itemId;
    const initialListIds = determineInitiallySelectedListIds(currentLists, productId, dealId, itemId);

    return (
        <Formik
            initialValues={{
                listId: '',
                listIds: initialListIds,
                name: ''
            }}
            onSubmit={async (values, formikActions) => {
                setSuccessSubmitNewList(false);
                setErrorMessage('');

                if (values.name && clickSaveList) {
                    const response: {data} = await createListV2(values, setAddToListModalErrorMessage);

                    if (!response?.data.createList) {
                        formikActions.setSubmitting(false);

                        return;
                    }

                    setSuccessSubmitNewList(true);

                    currentLists.push(response.data.createList);
                    reportListEvent('Create List');
                }

                if (!values.listId) {
                    closeModal();
                }

                const listFound = currentLists.find((list) => list.listId === values.listId);

                if (listFound) {
                    const text = productData?.product?.name || dealData?.deal?.dealTitle || itemData?.description || '';

                    const itemDataToUpdate = {
                        dealId: dealId ? Number(dealId) : null,
                        itemId: itemId ?? null,
                        listId: values.listId,
                        productId: productId ? Number(productId) : null,
                        text
                    };

                    try {
                        await createListItemV2(listFound.listItems, itemDataToUpdate);

                        reportListEvent(`Add ${dealId ? 'Deal ' : ''}to List`);

                        submitProdxAddToList({
                            aisleId,
                            responseId,
                            responseProductId
                        });

                        closeModal();
                    } catch (error) {
                        setErrorMessage(ToastMessages.ERROR_DEFAULT);
                    }
                }

                formikActions.setSubmitting(false);
            }}
        >
            {(formikProps) => (
                <AddToListsModalBody
                    addToListModalError={addToListModalError}
                    closeModal={closeModal}
                    errorMessage={errorMessage}
                    formikProps={formikProps}
                    itemDescription={dealId ? 'deal' : 'item'}
                    listData={listData}
                    setClickSaveList={setClickSaveList}
                    setErrorMessage={setErrorMessage}
                    successSubmitNewList={successSubmitNewList}
                />
            )}
        </Formik>
    );
};

export default AddProductOrDealToListForm;
