import {LinkButton} from '@hy-vee/web-core/lib/components/button';
import Link from 'next/link';
import styled from 'styled-components';

const StyledLinkButton = styled(LinkButton)`
    width: 100%;
    padding: 8px, 0;
`;

export const NextWrappedLink = ({href, children, ...props}) => (
    <Link data-testid={'next-link'} href={href} {...props}>
        {children}
    </Link>
);

export const WrappedLinkButton = ({href, onViewThisList, router, title, ...props}) => {
    return (
        <StyledLinkButton
            onClick={() => {
                onViewThisList();
                router.push(href);
            }}
        >
            <div {...props}>{title}</div>
        </StyledLinkButton>
    );
};

const sideNavLinks = () => {
    return [
        {
            heading: 'My Account',
            links: [
                {
                    href: '/my-account',
                    target: '_self',
                    text: 'Account Profile'
                },
                {
                    after: '®',
                    href: '/my-account/fuel-saver-plus-perks',
                    target: '_self',
                    text: 'Fuel Saver + Perks'
                },
                {
                    href: '/my-account/communication-preferences',
                    target: '_self',
                    text: 'Communication Preferences'
                },
                {
                    href: '/aisles-online/order-history',
                    target: '_self',
                    text: 'Order History'
                },
                {
                    href: '/my-account/addresses',
                    target: '_self',
                    text: 'Addresses'
                },
                {
                    component: NextWrappedLink,
                    href: '/preferences/payment',
                    target: '_self',
                    text: 'Payment Methods'
                },
                {
                    href: '/plus/preferences',
                    target: '_self',
                    text: 'Hy-Vee Plus Membership'
                }
            ]
        },
        {
            heading: 'Helpful Links',
            links: [
                {
                    href: '/shop/giftcards_checkbalance.aspx',
                    target: '_self',
                    text: 'Gift Card Balance Checker'
                },
                {
                    href: '/my-pharmacy/my-prescriptions/customer-info',
                    target: '_self',
                    text: 'Pharmacy Preferences'
                }
            ]
        }
    ];
};

export default sideNavLinks;
